import moment from "moment";

//! @ngInject
export function billingNewInvoiceBatchCtrl($scope, $rootScope, NgTableParams, DatabaseApi, itemSearchPageManager, selectionLogic, toaster, billingInvoicesProcessor, FilterUtils, $filter) {

    $scope.tableParams = null;
    $scope.isLoadingItems = false;
    $scope.offices = [];
    $scope.globalFilter = { val: "" };

    function initialize() {
        initPageManager();
        initLogics();
        getOffices();
        $scope.pageManager.resetSearchParamsByUrl();
        $scope.caregiversMap = DatabaseApi.caregivers() || {};
        $scope.patientsMap = DatabaseApi.patients() || {};
        $scope.selectedDates = { startDate: moment().subtract(7, "days"), endDate: moment() };
        $scope.billTypeOptions = [
            {
                id: 1,
                label: "Billable",
                value: false
            },
            {
                id: 2,
                label: "Pending",
                value: true
            },
            {
                id: 3,
                label: "All",
                value: null
            }
        ];
        $scope.selectedBillType = {id: 1};
        $scope.billTypeOptionSetting = {
            styleActive: true,
            singleSelection: true,
            selectionLimit: 1,
            smartButtonMaxItems: 1,
            closeOnSelect: true,
            showCheckAll: false,
            showUncheckAll: false
        };

        // Load filters
        const storageFilters = FilterUtils.rootFilters.billingNewInvoiceBatchCtrl;
        if (storageFilters !== undefined) {
            $scope.pageManager.setSearchParams(storageFilters);
        }

        console.log($scope.pageManager.isLoading());
    }

    $scope.$on("$destroy", function () {
        const filters = $scope.pageManager.searchParams;
        FilterUtils.rootFilters.billingNewInvoiceBatchCtrl = angular.copy(filters);
    });

    $scope.$on("refresh_visits", function () {
        $scope.loadItems();
    })

    const initPageManager = function () {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/visit_instances_extended");

        $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();
        $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();

        $scope.pageManager.initFromToDateParams();
        $scope.pageManager.initContractTypesParam();
        $scope.pageManager.initOfficeParam();
        $scope.pageManager.initSearchParam("officeId", "");
        $scope.pageManager.initSearchParamDeprecated("patientId", "", "patientId", undefined, undefined, "");
        $scope.pageManager.initSearchParamDeprecated("billType", "false", "pending", undefined, undefined, "");

    };

    function initLogics(items) {
        initSelection(items || []);
    };

    const initSelection = function (items) {
        $scope.selectionLogic = selectionLogic.createNewLogic((item) => {
            $scope.selectionLogic.addItemToCollection(item);
        });
        if (items) {
            items.forEach((item) => {
                $scope.selectionLogic.initItem(item);
            });
        }
    };

    $scope.loadItems = function () {
        $scope.isLoadingItems = true;
        $scope.globalFilter.val = '';

        $scope.pageManager.searchParams.from.value = $scope.selectedDates.startDate;
        $scope.pageManager.searchParams.to.value = $scope.selectedDates.endDate;
        $scope.pageManager.searchParams.billType.value = $scope.billTypeOptions.find(option => option.id === $scope.selectedBillType.id).value;

        $scope.pageManager
            .executeSearch()
            .then(function ({ data }) {
                $scope.visitInstances = data.visitInstances;
                initLogics($scope.visitInstances);
                renderPage($scope.visitInstances);
            })
            .catch(function (error) {
                toaster.pop("error", "Failed to load visit IDs");
            })
            .finally(function () {
                $scope.isLoadingItems = false;
            });
    };

    const mapPageItems = function (pageItems) {
        pageItems.forEach(function (item) {
            // contractId is contractTypeId
            if (!item.contractTypeName && item.contractId) {
                const contractType = $scope.contractTypesDataManager.getContractTypeById(item.contractId);
                item.contractTypeName = (contractType && contractType.name) || item.contractId;
            }

            if (item.caregiverId) {
                item.caregiver = $scope.caregiversMap[item.caregiverId];
            }
            if (item.patientId) {
                item.patient = $scope.patientsMap[item.patientId];
            }
        });
    };

    const renderPage = function (pageItems) {
        const hasPageItems = pageItems && pageItems.length;
        if (hasPageItems) {
            mapPageItems(pageItems);
        }

        $scope.tableParams = new NgTableParams(
            {
                count: 25,
            },
            {
                counts: [],
                dataset: pageItems,
            }
        );
    };

    const getOffices = function () {
        $scope.offices = DatabaseApi.offices();
        if (!$scope.offices || $scope.offices.length === 0) {
            DatabaseApi.get(`agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/offices`).then(function (res) {
                $scope.offices = res.data.offices;
                DatabaseApi.storeOffices(res.data.offices);
            }, function (err) {
                toaster.pop('error', "Something went wrong", "could not get offices");
            });
        }
    }

    $scope.applyGlobalSearch = (term) => {
        let filter = { $: term };
        if ($scope.tableParams) {
            angular.extend($scope.tableParams.filter(), filter);
            const visitsInstances = $filter('filter')($scope.visitInstances, $scope.globalFilter.val);
            initLogics(visitsInstances)
        }
    };

    $scope.clickTableRow = function (row) {
        if (!row) return;
        $rootScope.openVisitInstanceModal(row.id);
    };

    $scope.openInvoicesGeneratorModal = function () {
        const selectedItemIds = $scope.selectionLogic.getSelectedItems();
        billingInvoicesProcessor.openInvoicesGeneratorModalByVisits(selectedItemIds.map(item => item.id), $scope.loadItems);
    };

    $scope.openInvoicesGeneratorAndExporterModal = function () {
        const selectedItemIds = $scope.selectionLogic.getSelectedItems();
        billingInvoicesProcessor.openInvoicesGeneratorAndExporterModalByVisits(selectedItemIds.map(item => item.id), $scope.loadItems);
    };

    $scope.isInvoiceGeneratingDisabled = function () {
        const isDisabled = !$scope.selectionLogic || !$scope.selectionLogic.hasSelectedItems();
        return isDisabled;
    };

    $scope.exportTable = function () {
        $scope.loadingCSV = true;
        // Filter table data by global filter
        const visitsInstances = $filter('filter')($scope.visitInstances, $scope.globalFilter.val);
        const rows = [];
        const titles = ['Patient', 'Patient ID', 'Caregiver', 'Caregiver ID', 'Caregiver Code', 'Office', 'Contract', 'Service Code', 'Date', 'Schedule Time', 'Recorded Time', 'Current Total', 'Days For Timely Filing', 'Billing Units', 'Billing Rate', 'Billable Hours',
            'Patient Internal Medflyt ID', 'Patient HHA Exchange ID',
            'Patient Admission ID', 'Patient SSN', 'Patient Medicare Number', 'Patient Medicaid Number',
            'Patient Address', 'Patient Gender'];
        rows.push(titles);

        visitsInstances.forEach(dataRow => {
            const csvRow = [];
            csvRow.push(dataRow.patient?.displayName ?? '');
            csvRow.push(dataRow.patient?.displayId ?? '');
            csvRow.push(dataRow.caregiver?.displayName ?? '');
            csvRow.push(dataRow.caregiver?.displayId ?? '');
            csvRow.push(dataRow.caregiver?.caregiverCode ?? '');
            csvRow.push(dataRow.officeName || '');
            csvRow.push(dataRow.contractName || '');
            csvRow.push(dataRow.serviceCodeName || '');
            csvRow.push($filter("dateStringToLocaleDate")(dataRow.startTime) || '');
            csvRow.push($filter("hourMinutesRange")(dataRow, 'startTime', 'endTime'));
            csvRow.push($filter("hourMinutesRange")(dataRow, 'clockinTime', 'clockoutTime'));
            csvRow.push($filter("centsToDollars")(dataRow.billingTotal) || 0);
            csvRow.push(dataRow.timelyFilingLimitDaysLeft || '');
            csvRow.push(dataRow.billingUnitsThousandths / 1000);
            csvRow.push($filter("centsToDollars")(dataRow.billingRate) || 0);
            csvRow.push(dataRow.billableHours);
            csvRow.push(dataRow.patientId || '');
            csvRow.push(dataRow.patientExternalId || '');
            csvRow.push(dataRow.patientAdmissionId || '');
            csvRow.push(dataRow.patientSSN || '');
            csvRow.push(dataRow.patientMedicareNumber || '');
            csvRow.push(dataRow.patientMedicaidNumber || '');
            csvRow.push(dataRow.patientAddress ? '"' + dataRow.patientAddress.replace('#', '') + '"' : '" "');
            csvRow.push(dataRow.patientGender || '');
            rows.push(csvRow);
        });

        let csvContent = "data:text/csv;charset=utf-8,";
        rows.forEach(function (rowArray) {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", getExportedFileName());
        document.body.appendChild(link);

        link.click();
        $scope.loadingCSV = false;
    };

    const getExportedFileName = function () {
        const filename = "medflyt-billable-visits"
            + $filter("date")(new Date($scope.pageManager.searchParams.from.value), "yyyy-MM-dd")
            + "-to-"
            + $filter("date")(new Date($scope.pageManager.searchParams.to.value), "yyyy-MM-dd")
            + ".csv";
        return filename;
    }

    $scope.showOnlyActiveEntities = false;
    $scope.showEntitiesStatusOnSearch = false;

    $scope.handlePatientSelection = (patientId) => {
        $scope.pageManager.searchParams.patientId.value = patientId;
    }

    $scope.handlePatientDeselect = () => {
        $scope.pageManager.searchParams.patientId.value = "";
    }

    $scope.dateRangeOptions = {
        ranges: {
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 14 Days": [moment().subtract(13, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "Last 90 Days": [moment().subtract(89, "days"), moment()],
            "Last 180 Days": [moment().subtract(179, "days"), moment()],
            "Last 365 Days": [moment().subtract(364, "days"), moment()],
            "Until Now": [
                moment('2000-01-01'),
                moment()
            ]
        },
        alwaysShowCalendars: true,
        applyClass: "btn-primary",
        locale: {
            direction: "ltr date-range-picker-v2",
            format: "D MMM YY"
        },
        opens: "right",
        autoApply: true,
        minDate: new Date("2000-01-01"),
    };
    
    initialize();

};