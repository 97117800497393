import moment, { Moment } from "moment";

interface DateTimeDurationBindings {
  startDate: Moment;
  minDate: Date;
  maxDate: Date;
  fixedDuration: number | null;
  onChange: () => (data: DurationDataModel) => void;
}

interface DurationDataModel {
  startDate: Date;
  startTime: Moment;
  endTime: Moment;
  duration: null | string;
  totalDurationInMinutes: null | number;
}

interface DatePickerOptions {
  minDate: Date | null;
  maxDate: Date | null;
}

interface DateTimeDurationCtrlComponentOptions extends angular.IComponentOptions {
  bindings: Record<keyof DateTimeDurationBindings, string>;
}

class DateTimeDurationCtrl implements ng.IComponentController, DateTimeDurationBindings {
  private data: DurationDataModel;
  private isDatePickerOpen: boolean;
  private datePickerOptions: DatePickerOptions | null;
  startDate!: Moment;
  minDate!: Date;
  maxDate!: Date;
  fixedDuration!: number | null;
  onChange!: () => (data: DurationDataModel) => void;

  constructor(private $rootScope: ng.IRootScopeService) {
    this.isDatePickerOpen = false;
    const now = moment();
    this.data = {
      startDate: now.toDate(),
      startTime: now.clone().set({ hour: 9, minute: 0 }),
      endTime: now.clone().set({ hour: 13, minute: 0 }),
      duration: null,
      totalDurationInMinutes: null,
    };
    this.datePickerOptions = null;
  }

  $onInit = () => {
    this.datePickerOptions = {
      minDate: this.minDate ?? null,
      maxDate: this.maxDate ?? null,
    };

    this.data = {
      startDate: this.startDate.toDate(),
      startTime: this.startDate.clone().set({ hour: 9, minute: 0 }),
      endTime: this.startDate.clone().set({ hour: 13, minute: 0 }),
      duration: null,
      totalDurationInMinutes: null,
    };

    if (this.fixedDuration !== null) {
      this.updateDurationAccordingToEndTime();
    }

    this.updateShiftDuration();
  };

  getDurationStringByHoursAndMinutes = (hours: number, minutes: number) => {
    let durationString = "";
    if (hours > 0) durationString += hours + "H";
    if (minutes > 0) {
      if (durationString !== "") durationString += ":";
      durationString += minutes + "M";
    }

    return durationString;
  };

  updateDurationAccordingToEndTime = () => {
    if (this.fixedDuration === null) {
      throw new Error("cannot update time duration without duration binding");
    }

    this.data.endTime = this.data.startTime
      .clone()
      .set("minutes", this.data.startTime.minutes() + this.fixedDuration);
  };

  updateTimesDates = () => {
    this.data.startTime = moment(this.data.startDate)
      .set("hours", this.data.startTime.hours())
      .set("minutes", this.data.startTime.minutes());
    this.data.endTime = moment(this.data.startDate)
      .set("hours", this.data.endTime.hours())
      .set("minutes", this.data.endTime.minutes());
    if (this.fixedDuration !== null) {
      this.updateDurationAccordingToEndTime();
    } else {
      this.data.endTime = moment(this.data.startDate)
        .set("hours", this.data.endTime.hours())
        .set("minutes", this.data.endTime.minutes());
    }
    const isOverNight = this.data.endTime.isSameOrBefore(this.data.startTime);
    if (isOverNight) {
      this.data.endTime.set("date", this.data.startTime.date() + 1);
    }
  };

  updateShiftDuration = () => {
    this.updateTimesDates();
    const mDur = moment.duration(this.data.endTime.diff(this.data.startTime));
    let durationHours = Math.floor(mDur.asHours());

    this.data.totalDurationInMinutes = Math.floor(mDur.asMinutes());
    let durationMinutes = Math.floor(mDur.asMinutes()) % 60;
    if (durationHours < 0) durationHours += 24;
    if (durationMinutes < 0) durationMinutes += 60;

    this.data.duration = this.getDurationStringByHoursAndMinutes(durationHours, durationMinutes);
    if (this.onChange()) {
      this.onChange()(this.data);
    }
  };

  onTimeChange = (startEnd: any, newVal: Moment) => {
    if (newVal) {
      if (startEnd === "startTime") {
        this.data.startTime = this.data.startTime
          .clone()
          .set({ hour: newVal.hours(), minute: newVal.minutes() });
      } else {
        this.data.endTime = this.data.endTime
          .clone()
          .set({ hour: newVal.hours(), minute: newVal.minutes() });
      }
      this.updateShiftDuration();
    }
  };
}

export const DateTimeDuration: DateTimeDurationCtrlComponentOptions = {
  controller: DateTimeDurationCtrl,
  controllerAs: "ctrl",
  templateUrl: "admin/views/date-time-duration.html",
  bindings: {
    startDate: "<",
    minDate: "<",
    maxDate: "<",
    fixedDuration: "<",
    onChange: "&"
  },
};
