import { LocalDate, YearMonth, nativeJs } from "js-joda";
import moment from "moment";
import _ from "lodash";

export const caregiversTrainingCenterOverviewTable = {
  templateUrl: "admin/views/caregivers-training-center-overview-table.html",
  bindings: {
    queryType: "<",
    filters: "<",
    bundle: "<",
    caregiverStatistics: "<",
    isFetching: "<",
    isOverall: "<",
    onClickBundleBoost: "&",
    onClickCaregiverPhoto: "&",
    onClickCaregiverName: "&",
    onClickViewCertificate: "&",
    onClickDownloadCertificates: "&",
  },
  //! @ngInject
  controller: function (
    NgTableParams,
    Storage,
    $filter,
    wildcard,
    $rootScope,
    entityNotesModalService,
    TrainingCenterOverviewService,
    DatabaseApi,
    SweetAlert,
    toaster
  ) {
    this.caregiversTable = undefined;
    this.tableColumns = [];
    this.tableColumnsLength = 0;
    this.months = getMonthsList();

    this.inProgress = {
      bulkReminder: false,
    };

    /**
     * Caregiver status select options
     */
    this.caregiverStatusOptions = [
      { id: "ACTIVE", label: "Active", statusClass: "green" },
      { id: "ON_HOLD", label: "On Hold", statusClass: "yellow" },
      { id: "ON_LEAVE", label: "On Leave", statusClass: "orange" },
      { id: "PENDING", label: "Pending Application", statusClass: "lightblue" },
      { id: "SUSPENDED", label: "Inactive", statusClass: "gray" },
      { id: "TERMINATED", label: "Terminated", statusClass: "red" },
      { id: "QUIT", label: "Quit", statusClass: "gray" }
    ];

    this.trainingCenterReminderTypes = [
      { name: "Past due", value: "PAST_DUE" },
      { name: "Next 7 days", value: "NEXT_WEEK" },
      { name: "Started and not completed", value: "STARTED_NOT_COMPLETED" },
      { name: "Not started", value: "NOT_STARTED" },
    ];

    this.$onInit = () => {
      this.initTableColumns();
    };

    this.$onChanges = () => {
      if (this.caregiverStatistics) {
        this.setCaregiversTable();
        this.saveTableColumnsSettings();
      }
    };

    this.handleClickCaregiverPhoto = (row) => {
      this.onClickCaregiverPhoto({ row });
    };

    this.handleClickCaregiverName = (row) => {
      this.onClickCaregiverName({ row });
    };

    this.handleClickViewCertificate = (row) => {
      this.onClickViewCertificate({ row });
    };

    this.handleClickExportTable = () => {
      this.exportTable();
    };

    this.handleClickBoostBundleView = ($event, bundle, caregiverId) => {
      $event.stopPropagation();
      this.onClickBundleBoost({ bundle, caregiverId });
    };

    this.handleChangeTableSetting = () => {
      this.saveTableColumnsSettings();
    };

    this.handleClickDownloadCertificates = (month) => {
      if (this.isOverall) {
        return;
      }

      this.onClickDownloadCertificates({
        bundle: this.bundle,
        month: month,
      });
    };

    this.sendTrainingCenterReminderByType = async (group) => {
      this.inProgress.bulkReminder = true;

      const url = appendUrlParams(
        wildcard(
          "agencies/:agencyId/agency_members/:agencyMemberId/training_center/bundles/:bundleId/send_training_center_reminder",
          $rootScope.agencyId,
          $rootScope.agencyMemberId,
          this.bundle.bundleId
        ),
        group,
        this.filters
      );

      DatabaseApi.post(url)
        .then(({ data }) => {
          if (data.caregiverCount === 0) {
            let explanation;
            switch (group) {
              case "PAST_DUE":
                explanation = "No caregivers have due dates past due";
                break;
              case "NEXT_WEEK":
                explanation = "No caregivers have due dates in the next 7 days";
                break;
              case "STARTED_NOT_COMPLETED":
                explanation =
                  "There are no caregivers who started but didn't complete the bundle";
                break;
              case "NOT_STARTED":
                explanation =
                  "There are no caregivers who haven't started the bundle";
            }
            toaster.pop("warning", "No reminders sent", explanation);
          } else {
            toaster.pop(
              "success",
              `Reminder sent to ${data.caregiverCount} caregivers`
            );
          }
        })
        .catch(() => toaster.pop("error", "Couldn't send reminder"))
        .finally(() => (this.inProgress.bulkReminder = false));
    };

    function appendUrlParams(urlBase, group, filters) {
      const {
        dueDateRange,
        installationStatus,
        complianceStatus,
        caregiverStatus,
        caregiverOffices,
      } = filters;

      let newUrl = "";

      const dueFrom = LocalDate.from(nativeJs(dueDateRange.startDate));
      const dueTo = LocalDate.from(nativeJs(dueDateRange.endDate));

      newUrl = `${urlBase}?from=${dueFrom}&to=${dueTo}`;

      if (newUrl !== "") {
        if (installationStatus.length && installationStatus.length < 2) {
          newUrl += `&installation=${installationStatus
            .map(({ id }) => id)
            .join(",")}`;
        }

        if (complianceStatus.length && complianceStatus.length < 2) {
          newUrl += `&compliance=${complianceStatus
            .map(({ id }) => id)
            .join(",")}`;
        }

        if (caregiverStatus.length) {
          newUrl += `&status=${caregiverStatus.map(({ id }) => id).join(",")}`;
        }

        if (caregiverOffices.length) {
          newUrl += `&offices=${caregiverOffices
            .map(({ id }) => id)
            .join(",")}`;
        }
      }

      newUrl = `${newUrl}&group=${group}`;

      return newUrl;
    }

    /**
     * Caregiver status map for easier access from template
     */
    this.caregiverStatusesMap = {};

    this.caregiverStatusOptions.map((status) => {
      this.caregiverStatusesMap[status.id] = status;
    });

    this.setCaregiversTable = () => {
      // mock started watch
      const now = new Date();
      const lastWeekStart = moment().subtract(1, 'weeks').startOf('isoWeek').toDate();

      this.caregiversTable = new NgTableParams(
        {
          page: 1,
          count: 10,
        },
        {
          filterDelay: 0,
          dataset: this.caregiverStatistics,
        }
      );
    };

    this.initTableColumns = () => {
      let tableStorageKey = "trainingCenterStatisticsTableColumn1";
      if (this.isOverall) {
        tableStorageKey = "trainingCenterOverallStatisticsTableColumn";
      }
      const savedColumns = Storage.getObject(tableStorageKey);

      let BundleNameCol = false;
      let BundleIdCol = false;
      let ExemptedFromBundleCol = false;
      let TotalProgressCol = false;
      let YearCol = true;
      let startedWatchingCol = false;
      let EnrolledCol = false;
      let renameCertificationCol = false;

      if (savedColumns && Object.keys(savedColumns).length) {
        Object.keys(savedColumns).forEach(function (t) {
          if (t === "bundleName") BundleNameCol = true;
          if (t === "bundleId") BundleIdCol = true;
          if (t === "exemptedFromBundle") ExemptedFromBundleCol = true;
          if (t === "totalProgress") TotalProgressCol = true;
          if (t === "year") YearCol = true;
          if (t === "startedWatching") startedWatchingCol = true;
          if (t === "enrolled") EnrolledCol = true;
          if (t === "certification") renameCertificationCol = true;
        });

        if (!BundleNameCol && !this.isOverall) {
          savedColumns.bundleName = { show: true, displayName: "Bundle name" };
        }
        if (!BundleIdCol && !this.isOverall) {
          savedColumns.bundleId = { show: true, displayName: "Bundle id" };
        }
        if (!TotalProgressCol && !this.isOverall) {
          savedColumns.totalProgress = { show: true, displayName: "Total progress" };
        }
        if (!TotalProgressCol && !this.isOverall) {
          savedColumns.year = { show: true, displayName: "Year" };
        }
        if (!startedWatchingCol && !this.isOverall) {
          savedColumns.startedWatching = { show: true, displayName: "Started Watching" };
        }
        if (!EnrolledCol && !this.isOverall) {
          savedColumns.enrolled = { show: true, displayName: "Enrolled" };
        }
        if (!ExemptedFromBundleCol) {
          savedColumns.exemptedFromBundle = { show: true, displayName: "Exempted from bundle" };
        }
        if (renameCertificationCol) {
          savedColumns.certifications = { show: savedColumns.certification.show, displayName: "Certifications" };
          delete savedColumns.certification;
        }
        this.tableColumnsLength = Object.values(savedColumns).filter(
          Boolean
        ).length;
        this.tableColumns = savedColumns;
      } else {
        this.tableColumns = {
          photo: { show: true, displayName: "Photo" },
          fullName: { show: true, displayName: "Full name" },
          totalProgress: { show: true, displayName: "Total progress" },
          timeRequiredForCertificate: { show: true, displayName: "Time required for certificate" },
          totalTimeWatched: { show: true, displayName: "Time Completed" },
          startedWatching: { show: true, displayName: "Started watching" },
          timeRemainingForCertificate: { show: true, displayName: "Time remaining for certificate" },
          isCompliant: { show: true, displayName: "Compliance" },
          caregiverCode: { show: false, displayName: "Code" },
          offices: { show: false, displayName: "Offices" },
          caregiverBranch: { show: false, displayName: "Branch" },
          gender: { show: false, displayName: "Gender" },
          birthDate: { show: false, displayName: "Birthdate" },
          languages: { show: false, displayName: "Languages" },
          certifications: { show: false, displayName: "Certifications" },
          installation: { show: false, displayName: "Installation" },
          installedAt: { show: false, displayName: "Installed at" },
          uninstalledAt: { show: false, displayName: "Uninstalled at" },
          lastSeen: { show: false, displayName: "Last seen" },
          caregiverAddress: { show: false, displayName: "Address" },
          phones: { show: false, displayName: "Phone" },
          status: { show: false, displayName: "Status" },
          hireDate: { show: false, displayName: "Hire date" },
          workAreaPreference: { show: false, displayName: "Area preference" },
          stateRegistryNumber: { show: false, displayName: "State registry number" },
          enrolled: { show: true, displayName: "Enrolled" }
        };
        if (!this.isOverall) {
          this.tableColumns.year = { show: true, displayName: "Year" };
          this.tableColumns.lastDueDate = { show: true, displayName: "LDD" };
          this.tableColumns.nextDueDate = { show: true, displayName: "NDD" };
          this.tableColumns.certificate = { show: true, displayName: "Certificate" };
          this.tableColumns.completionDate = { show: true, displayName: "Completion date" };
          this.tableColumns.bundleId = { show: false, displayName: "Bundle Id" };
          this.tableColumns.bundleName = { show: false, displayName: "Bundle Name" };
          this.tableColumns.exemptedFromBundle = { show: false, displayName: "Exempted from bundle" };
        }
      }
    };

    this.saveTableColumnsSettings = () => {
      if (this.tableColumns) {
        let tableStorageKey = "trainingCenterStatisticsTableColumn1";
        if (this.isOverall) {
          tableStorageKey = "trainingCenterOverallStatisticsTableColumn";
        }
        Storage.setObject(tableStorageKey, this.tableColumns);
        this.tableColumnsLength = Object.values(this.tableColumns).filter(
          Boolean
        ).length;
      }
    };

    this.handleOverallTableExport = () => {
      const activeColumns = Object.keys(
        _.pickBy(this.tableColumns, (columnKey) => columnKey.show === true)
      );

      TrainingCenterOverviewService.getStatisticsTableExportFileUrl(
        this.filters,
        activeColumns
      )
        .then((url) => {
          if (!url) return;

          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          document.body.appendChild(downloadLink);
          SweetAlert.swal.close();
          downloadLink.click();
        })
        .catch(() => {
          SweetAlert.swal.close();
          toaster.pop("error", "Something went wrong", "Couldn't get file")
        });
    };

    this.exportTable = () => {
      if (this.isOverall) {
        SweetAlert.swal(
          {
            title: "Export overall table",
            text: "Notice, overall table export can take a while..",
            type: "info",
            showCancelButton: true,
            confirmButtonColor: "#3898ff",
            confirmButtonText: "OK, Export..",
            closeOnConfirm: false,
            showLoaderOnConfirm: true,
          },
          (hasConfirmed) => hasConfirmed && this.handleOverallTableExport()
        );
        return;
      }

      const tableData = this.caregiverStatistics;
      if (tableData.length <= 0) {
        toaster.pop("warning", "Nothing to export", "The table is empty");
        return;
      }

      const rows = [];
      let titles = [];
      const ignoredTitles = ["photo", "certificate", "Photo", "Certificate"];

      for (const key in this.tableColumns) {
        if (this.tableColumns[key].show && !ignoredTitles.includes(key)) {
          titles.push(this.tableColumns[key].displayName);
        }
      }

      titles = titles.filter((title) => !ignoredTitles.includes(title));

      rows.push(titles);

      for (const caregiver of tableData) {
        const row = [];

        for (const title of titles) {
          let cell = "";

          cell = setColumnValue(title, caregiver);
          cell = cell.toString().replace("\n", " ");

          row.push(cell);
        }

        rows.push(row);
      }

      let csvContent = "";

      for (const row of rows) {
        const joinedRow = row.join(",");
        csvContent += joinedRow + "\r\n";
      }

      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "medflyt-caregiver-export.csv");

      link.click();
      window.URL.revokeObjectURL(url);
    };

    this.handleNotesModalOpen = function (profileId, profileName) {
      entityNotesModalService.handleNotesModalOpen({
        profileId,
        profileName,
      });
    };

    const setColumnValue = (column, row) => {
      let result = "";

      switch (column) {
        case "Full name":
          result = `"${$filter("fullName")(row.caregiver) || ""}"`;
          break;
        case "Bundle Id":
          result = `"${row.bundleId || ""}"`;
          break;
        case "Bundle Name":
          result = `"${row.bundleName || ""}"`;
          break;
        case "Exempted from bundle":
          result = `"${row.exemptedFromBundle ? "Exempted" : ""}"`;
          break;
        case "Last due date":
        case "LDD":
          result = `="${$filter("mfShortDate")(row.lastDueDate) || ""}"`;
          break;
        case "Year":
          result = `"${row.year}"`;
          break;
        case "Next due date":
        case "NDD":
          result = `="${$filter("mfShortDate")(row.nextDueDate) || ""}"`;
          break;
        case "Time required for certificate":
          result = `"${$filter("secondsToTime")(row.totalRequiredForCertificateSeconds) || ""}"`;
          break;
        case "Total time watched":
        case "Time Completed":
          result = `"${$filter("secondsToTime")(row.totalWatchedSeconds) || ""}"`;
          break;
        case "Time remaining for certificate":
          result = `"${$filter("secondsToTime")(row.totalRemainderForCertificateSeconds) || ""}"`;
          break;
        case "Compliance":
          result = `"${row.isCompliant ? "Compliant" : "Incompliant" || ""}"`;
          break;
        case "Completion date":
          result = `="${$filter("mfShortDate")(row.completionDate) || ""}"`;
          break;
        case "Code":
          result = `"${row.caregiver.caregiverCode || ""}"`;
          break;
        case "Branch":
          result = `"${row.caregiver.caregiverBranch || ""}"`;
          break;
        case "Gender":
          result = `"${row.caregiver.gender || ""}"`;
          break;
        case "Birthdate":
          result = `="${$filter("mfShortDate")(row.caregiver.birthDate)}"` || "";
          break;
        case "Languages":
          result = `"${row.caregiver.languages.join(", ") || ""}"`;
          break;
        case "Certifications":
          result = `"${row.caregiver.certifications.join(", ")  || ""}"`;
          break;
        case "Installation":
          result = row.caregiver.appInstalled
            ? "Installed"
            : "Un-Installed" || "";
          break;
        case "Installed at":
          const installedDates = row.caregiver.appInstalledAt.map((date) =>
            $filter("mfShortDate")(date)
          );
          result = `"${installedDates.join(" | ") || ""}"`;
          break;
        case "Uninstalled at":
          const uninstalledDates = row.caregiver.appUninstalledAt.map((date) =>
            $filter("mfShortDate")(date)
          );
          result = `"${uninstalledDates.join(" | ") || ""}"`;
          break;
        case "Last seen":
          result = row.caregiver.online
            ? "Online"
            : (row.caregiver.lastSeen !== -1
                ? `="${$filter("mfShortDate")(row.caregiver.lastSeen)}"`
                : "") || "";
          break;
        case "Address":
          result = `"${row.caregiver.caregiverAddress || ""}"`;
          break;
        case "Phone":
          const phoneNumbers = row.caregiver.phoneNumbers.map(
            (phone) =>
              `${$filter("americanphone")(phone.phoneNumber)} (${phone.type})`
          );
          result = `"${phoneNumbers.join(", ") || ""}"`;
          break;
        case "Status":
          result = `"${this.caregiverStatusesMap[row.caregiver.status].label || ""}"`;
          break;
        case "Hire date":
          result = `="${$filter("mfShortDate")(row.caregiver.hireDate) || ""}"`;
          break;
        case "Work area preference":
          result = `"${row.caregiver.workAreaPreference || ""}"`;
          break;
        case "State registry number":
          result = `"${row.caregiver.stateRegistryNum || ""}"`;
          break;
        case "Offices":
          result = `"${row.__officeNames}"`;
          break;
        case "Started watching":
          result = `="${$filter("mfShortDate")(row.startedWatching) || ""}"`;
          break;
        case "Total progress":
          const percentage = (row.totalRequiredForCertificateSeconds - row.totalRemainderForCertificateSeconds) / row.totalRequiredForCertificateSeconds * 100;
          result = percentage.toFixed(2) + '%';
          break;
        case "Enrolled":
          result = row.enrolled ? "Yes" : "No";
          break;
        default:
          console.error(`Unhandled case for "${column}" in setColumnValue() switch-case`);
          break;
      }

      return result;
    };

    function getMonthsList() {
      const fromMonth = YearMonth.now().minusYears(2).toString();
      const currentMonth = YearMonth.now().toString();
      const months = [fromMonth];

      while (months[0] !== currentMonth) {
        const lastMonth = months[0];
        months.unshift(
          YearMonth.parseString(lastMonth).plusMonths(1).toString()
        );
      }

      return months;
    }

    this.handleClickCaregiverRow = (clickedRow) => {
      if (!this.isOverall) {
        return;
      }

      if (
        this.showCaregiverBundlesTable !== undefined &&
        this.showCaregiverBundlesTable === clickedRow.caregiver.id
      ) {
        delete clickedRow.bundlesDetails;
        this.showCaregiverBundlesTable = undefined;
        return;
      }

      this.showCaregiverBundlesTable = clickedRow.caregiver.id;

      TrainingCenterOverviewService.getCaregiverBundlesStats(
        this.filters,
        clickedRow.caregiver.id
      ).then((res) => {
        const { rows } = res.data;
        if (rows) {
          clickedRow.bundlesDetails = {
            bundles: rows,
          };
        }
      });
    };
  },
};
