//! @ngInject
export function officeSetupModalCtrl(
  GoogleAddressService,
  $scope,
  $rootScope,
  $uibModalInstance,
  data,
  toaster,
  officesService,
  mfModal
) {
  
  const addressObject = {
    address: data.address || "",
    addressComponents: data.addressComponents || null,
    timezone: data.timezone || ""
  }
  $scope.form = {
    name: {
      data: data.name || "",
      error: false,
    },
    phoneNumber: {
      data: data.phoneNumber || "",
      error: false,
    },
    tax_id: {
      data: data.tax_id || "",
      error: false,
    },
    npi: {
      data: data.npi || "",
      error: false,
    },
    mmis: {
      data: data.mmis || "",
      error: false,
    },
    pfi: {
      data: data.pfi || "",
      error: false,
    },
    address: {
      data: addressObject || {},
      error: false
    },
    address2: {
      data: data.address2 || "",
      error: false
    },
    external_name: {
      data: data.external_name || "",
      error: false,
    },
    allowBillPendingVisits: {
      data: data.allowBillPendingVisits || false,
      error: false,
    },
    onlyPayBilledVisits: {
      data: data.onlyPayBilledVisits || false,
      error: false,
    }
  };

  function validateForm () {
    const errors = [];
    if (!$scope.form.name.data || !$scope.form.name.data.length) {
      errors.push({
        field: "name",
        message: "Please enter an office name"
      });
    }
    if (!$scope.form.tax_id.data || !$scope.form.tax_id.data.length) {
      errors.push({
        field: "tax_id",
        message: "Please enter an Employer Identification Number (EIN)"
      });
    }
    if (!$scope.form.npi.data || !$scope.form.npi.data.length) {
      errors.push({
        field: "npi",
        message: "Please enter a National Provider Identifier (NPI)"
      });
    }
    if (!$scope.form.mmis.data || !$scope.form.mmis.data.length) {
      errors.push({
        field: "mmis",
        message: "Please enter a Medical Marijuana Information System (MMIS)"
      });
    }
    if (!$scope.form.address.data || !$scope.form.address.data.address.length) {
      errors.push({
        field: "address",
        message: "Please enter an address"
      });
    }

    return errors;
  }

  $scope.submitForm = function () {
    const formFields = Object.keys($scope.form);    
    formFields.forEach((field) => $scope.form[field].error = false);

    const errors = validateForm();
    errors.forEach((error) => $scope.form[error.field].error = true);
    const errorMessage = errors.map(error => error.message).join("\n");

    if (errors.length !== 0) {
      return mfModal.createSimple({
        subject: "Error",
        message: errorMessage,
        variant: "danger",
      });
    }

    if (data.id) {
      officesService
        .updateOffice($rootScope.agencyId, $rootScope.agencyMemberId, data.id, {
          tax_id: $scope.form.tax_id.data,
          npi: $scope.form.npi.data,
          mmis: $scope.form.mmis.data,
          pfi: $scope.form.pfi.data,
          address: $scope.form.address.data,
          name: $scope.form.name.data,
          external_name: $scope.form.external_name.data,
          phoneNumber: formatPhoneNumber($scope.form.phoneNumber.data),
          address2: $scope.form.address2.data,
          allowBillPendingVisits: $scope.form.allowBillPendingVisits.data,
          onlyPayBilledVisits: $scope.form.onlyPayBilledVisits.data
        })
        .then(() => {
          toaster.pop("success", "Office saved");
          $scope.closeModal();
        })
        .catch(() => {
          toaster.pop(
            "error",
            "Something went wrong",
            "Could not change Office settings"
          );
        });
    } else {
      officesService
        .insertOffice($rootScope.agencyId, $rootScope.agencyMemberId, {
          tax_id: $scope.form.tax_id.data,
          npi: $scope.form.npi.data,
          pfi: $scope.form.pfi.data,
          address: $scope.form.address.data,
          name: $scope.form.name.data,
          external_name: $scope.form.external_name.data,
          phoneNumber: formatPhoneNumber($scope.form.phoneNumber.data),
          address2: $scope.form.address2.data,
          allowBillPendingVisits: $scope.form.allowBillPendingVisits.data,
          onlyPayBilledVisits: $scope.form.onlyPayBilledVisits.data
        })
        .then(() => {
          toaster.pop("success", "Office saved");
          $scope.closeModal();
        })
        .catch(() => {
          toaster.pop("error", "Something went wrong", "could not add office");
        });
    }
  };

  $scope.resetError = function (field) {
    $scope.form[field].error = false;
  };

  $scope.closeModal = function () {
    $uibModalInstance.close("ok");
  };

  $scope.exit = function () {
    $uibModalInstance.dismiss();
  };

  const formatPhoneNumber = (phone) => {
    if (phone.length === 0) return null;
    if (phone.substr(0, 2) !== "+1") return `+1${phone}`;

    return phone;
  };

  $scope.onOfficeAddressChange = async function () {
    const address = $scope.form.address.data.address;
    if (address && address.address_components) {
      try {
        $scope.officeAddressComponents = await GoogleAddressService.getAddressComponentsFromMedflytGeoCode(address);
        $scope.form.address.data = {
          address: $scope.officeAddressComponents.formatedAddressDetails.fullAddress,
          timezone: "America/New_York",
          addressComponents: $scope.officeAddressComponents
        };
      }
      catch (e) {
        toaster.pop('error', 'Invalid address: ' + e.message);
      }

    } else {
      $scope.form.address.data = null;
    }
  };
};
