import moment from "moment";

//! @ngInject
export function coronaVirusReportsCtrl($scope, $rootScope, NgTableParams, toaster, DatabaseApi, offices, $filter, $uibModal, Storage, wildcard) {

    function initialize() {
        if (Object.keys($scope.caregiversMap).length === 0) {
            return;
        }
        $scope.patientsMap = DatabaseApi.patients();
        $scope.gotData = true;
        initStatusesOptions();
        initTableColumns();
        getOffices();
        $scope.loadItems();

        $scope.updateFromOptions();
    }

    let caregiversWithScheduled = new Set();
    let patientsWithScheduled = new Set();
    $scope.caregiversMap = DatabaseApi.caregivers() || {};
    $scope.coronaReports = [];
    $scope.gotData = false;

    $scope.offices = [];

    var getOffices = function () {
        $scope.offices = offices.map(office => ({
            id: office.id,
            label: office.name
        }));
    }

    $scope.filterBy = {
        displayType: 'Caregivers',
        caregiverCertification: [],
        caregiverLanguage: [],
        caregiverStatus: [{id: 1}],
        caregiverInstallation: [],
        caregiverOffices: [],
        patientStatus: [],
        riskStatus: { risk: true },
        fromDate: new Date(moment().subtract(1, "days")),
        toDate: new Date(moment()),
        scheduledShifts: true,
    };

    const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
    $scope.certifications = activeAgencyCertifications
        .map((certificationItem, index) => ({
            id: index,
            label: certificationItem.certification
        }));

    $scope.languages = [
      { id: 1, label: "English" },
      { id: 2, label: "Spanish" },
      { id: 3, label: "Chinese" },
      { id: 4, label: "Portuguese" },
      { id: 5, label: "French" },
      { id: 6, label: "Russian" },
      { id: 7, label: "Arabic" },
    ];

    $scope.caregiverInstallations = [
        { id: 1, value: "installed", label: "Installed" },
        { id: 2, value: "uninstalled", label: "Uninstalled" },
        { id: 3, value: "notinstalled", label: "Not Installed" }
    ];

    $scope.caregiverStatuses = [
        { id: 1, value: "ACTIVE", label: "Active", statusClass: "green" },
        { id: 2, value: "ON_HOLD", label: "On Hold", statusClass: "yellow" },
        { id: 3, value: "ON_LEAVE", label: "On Leave", statusClass: "orange" },
        { id: 4, value: "PENDING", label: "Pending Application", statusClass: "lightblue" },
        { id: 5, value: "SUSPENDED", label: "Inactive", statusClass: "azur" },
        { id: 6, value: "TERMINATED", label: "Terminated", statusClass: "red" },
        { id: 7, value: "QUIT", label: "Quit", statusClass: "azur" }
    ];

    $scope.patientStatuses = [
        { id: 1, value: "ELIGIBLE", label: "Eligible", statusClass: "green" },
        { id: 2, value: "ACCEPTED", label: "Accepted", statusClass: "lightblue" },
        { id: 3, value: "PENDING_FILES", label: "Pending Files", statusClass: "orange" },
        { id: 4, value: "REFERRAL", label: "Referral", statusClass: "red" },
        { id: 5, value: "ACTIVE", label: "Active", statusClass: "green" },
        { id: 6, value: "DISCHARGED", label: "Discharged", statusClass: "red" },
        { id: 7, value: "ON_HOLD", label: "On Hold", statusClass: "orange" },
        { id: 8, value: "HOSPITALIZED", label: "Hospitalized", statusClass: "orange" },
        { id: 9, value: "VACATION", label: "Vacation", statusClass: "orange" },
    ];

    $scope.caregiverStatusesMap = {};
    $scope.patientStatusesMap = {};

    $scope.caregiverStatuses.forEach(status => {
        $scope.caregiverStatusesMap[status.value] = status;
    });

    $scope.patientStatuses.forEach(status => {
        $scope.patientStatusesMap[status.value] = status;
    });

    const initStatusesOptions = () => {
        $scope.agencyPatientStatuses = DatabaseApi.agencyPatientStatuses();
        $scope.patientStatuses = $scope.patientStatuses.filter((status) => {
          const foundStatus = $scope.agencyPatientStatuses.find(
            (agencyStatus) => agencyStatus.text === status.value
          );
          return foundStatus !== undefined && foundStatus.active;
        });
    }

    function initTableColumns() {
        var columns = Storage.getObject("coronaReportsTableSettings");
        let Offices = false;
        let isDateExists = false;
        let isCertificationExists = false;

        if (columns && Object.keys(columns).length) {
            Object.keys(columns).forEach(function(t) {
                if (t === "Caregiver Offices") Offices = true;
                if (t === "Date") isDateExists = true;
                if (t === "Caregiver Certification") isCertificationExists = true;
                if (columns[t]) $scope.tableColumnsLength++;
            });

            if (!Offices) {
                columns["Caregiver Offices"] = true;
            }

            if (!isDateExists) {
                columns["Date"] = true;
            }

            if (isCertificationExists) {
                delete columns["Caregiver Certification"];
                columns["Caregiver Certifications"] = true;
            }

            $scope.tableColumns = columns;
        } else {
            $scope.tableColumns = {
                "Caregiver Name": true,
                "Caregiver Code": true,
                "Caregiver Branch": false,
                "Caregiver Gender": false,
                "Caregiver Birthdate": false,
                "Caregiver Languages": false,
                "Caregiver Certifications": false,
                "Caregiver Installation": false,
                "Caregiver Installed At": false,
                "Caregiver Uninstalled At": false,
                "Caregiver Last Seen": false,
                "Caregiver Address": false,
                "Caregiver Offices": false,
                "Caregiver Phone": false,
                "Caregiver Status": false,
                "Caregiver Hire Date": false,
                "Caregiver Area Preference": false,
                "Caregiver State Registry Number": false,
                "Patient Name": true,
                "Patient Created At": false,
                "Patient Address": false,
                "Patient Home Phone Number": false,
                "Patient Mobile Phone Number": false,
                "Patient Status": false,
                "Date": true,
                "Submitted At": true,
                "Status": true,
                "Notes": true
            };
        }
        $scope.$watch(
            "tableColumns",
            function() {
              if ($scope.tableColumns) {
                Storage.setObject("coronaReportsTableSettings", $scope.tableColumns);
                $scope.tableColumnsLength = 0;
                Object.keys($scope.tableColumns).forEach(function(t) {
                  if ($scope.tableColumns[t]) $scope.tableColumnsLength++;
                });
                filterTable();
              }
            },
            true
        );
    }

    $scope.getLocalDateByDate = function (d) {
        return d.toJSON().slice(0, 10);
    }

    var filterByMethods = {
        reportMatchSelectedDisplayType: (report, displayType) => {
            if (displayType === 'Caregivers' && (report.patientId || report.patientName)) {
                return false;
            } else if (displayType === 'Patients' && (!report.patientId && !report.patientName)) {
                return false;
            }
            return true;
        },
        reportHasCaregiverCertification: (report, selectedCertifications) => report.caregiver.certifications.some(c => selectedCertifications.includes(c)),
        reportHasCaregiverLanguage: (report, selectedLanguages) => report.caregiver.languages.find(function (lang) { return selectedLanguages.indexOf(lang) !== -1; }) !== undefined,
        reportHasCaregiverStatus: (report, selectedStatuses) => selectedStatuses.indexOf(report.caregiver.status) !== -1,
        reportHasCaregiverInstallation: (report, selectedInstallations) => selectedInstallations.indexOf(report.caregiver.confirmed) !== -1,
        reportHasCaregiverOffice: (report, selectedOffices) => report.caregiver.officeIds.find(function (office) { return selectedOffices.indexOf(office) !== -1; }) !== undefined,
        reportHasPatientStatus: (report, selectedStatuses) => selectedStatuses.indexOf(report.patient.status) !== -1,
        reportHasRiskStatus: (report, riskStatuses) => {
            if (report.risk === true) return riskStatuses.risk === true;
            else if (report.risk === false) return riskStatuses.done === true;
            else return riskStatuses.missing === true;
        }
    };

    function filterTable() {
        if (!$scope.coronaReports) return;

        var filters = [];

        var selectedDisplayType = $scope.filterBy.displayType;
        if (selectedDisplayType) {
            filters.push(function (report) { return filterByMethods.reportMatchSelectedDisplayType(report, selectedDisplayType); });
        }

        var selectedCaregiverCertifications = $scope.filterBy.caregiverCertification.map(function (obj) {
            return $scope.certifications.find(function (cert) { return cert.id === obj.id; }).label;
        });
        if (selectedCaregiverCertifications.length > 0) {
            filters.push(function (report) { return filterByMethods.reportHasCaregiverCertification(report, selectedCaregiverCertifications); });
        }

        var selectedCaregiverLanguages = $scope.filterBy.caregiverLanguage.map(function (obj) {
            return $scope.languages.find(function (lang) { return lang.id === obj.id; }).label;
        });
        if (selectedCaregiverLanguages.length > 0) {
            filters.push(function (report) { return filterByMethods.reportHasCaregiverLanguage(report, selectedCaregiverLanguages); });
        }

        var selectedCaregiverStatuses = $scope.filterBy.caregiverStatus.map(function (obj) {
            return $scope.caregiverStatuses.find(function (stat) { return stat.id === obj.id; }).value;
        });
        if (selectedCaregiverStatuses.length > 0) {
            filters.push(function (report) { return filterByMethods.reportHasCaregiverStatus(report, selectedCaregiverStatuses); });
        }

        var selectedCaregiverInstallations = $scope.filterBy.caregiverInstallation.map(function (obj) {
            return $scope.caregiverInstallations.find(function (stat) { return stat.id === obj.id; }).value;
        });
        if (selectedCaregiverInstallations.length > 0) {
            filters.push(function (report) { return filterByMethods.reportHasCaregiverInstallation(report, selectedCaregiverInstallations); });
        }

        var selectedCaregiverOffices = $scope.filterBy.caregiverOffices.map(function (obj) {
            return $scope.offices.find(function (office) { return office.id === obj.id; }).id;
        });
        if (selectedCaregiverOffices.length > 0) {
            filters.push(function (report) { return filterByMethods.reportHasCaregiverOffice(report, selectedCaregiverOffices); });
        }

        var selectedPatientStatuses = $scope.filterBy.patientStatus.map(function (obj) {
            return $scope.patientStatuses.find(function (stat) { return stat.id === obj.id; }).value;
        });
        if (selectedDisplayType === "Patients" && selectedPatientStatuses.length > 0) {
            filters.push(function (report) { return filterByMethods.reportHasPatientStatus(report, selectedPatientStatuses); });
        }

        var selectedRiskStatuses = $scope.filterBy.riskStatus;
        if (selectedRiskStatuses) {
            filters.push(function (report) { return filterByMethods.reportHasRiskStatus(report, selectedRiskStatuses); });
        }

        if ($scope.filterBy.scheduledShifts) {
            const set = selectedDisplayType === "Patients" ? patientsWithScheduled : caregiversWithScheduled;
            const column = selectedDisplayType === "Patients" ? "patientId" : "caregiverId";
            filters.push((report) => set.has(report[column]));
        }

        var filteredReports = $scope.coronaReports;
        if (filters.length > 0) {
            filteredReports = filteredReports.filter(function (report) {
                var isFiltered = true;
                for (var idx = 0; isFiltered && idx < filters.length; idx++) {
                    isFiltered = isFiltered && filters[idx](report);
                }
                return isFiltered;
            });
        }

        initTable(filteredReports);
    }

    $scope.globalFilter = { val: "" };
    $scope.applyGlobalSearch = function (term) {
        var filter = { $: term };
        if ($scope.tableParams) {
            angular.extend($scope.tableParams.filter(), filter);
        } else {
            console.log('no table');
        }
    };

    function initTable(coronaReports) {
        $scope.tableData = coronaReports;
        var options = {
            count: 25,
            sorting: { submittedAt: "desc" }
        };
        $scope.tableParams = new NgTableParams(options, {
            counts: [10, 25, 50, 100],
            dataset: $scope.tableData
        });

        if ($scope.globalFilter.val) {
            $scope.applyGlobalSearch($scope.globalFilter.val);
        }
    }

    var mapCoronaReports = function (reports) {
        var caregiver;
        $scope.coronaReports = [];
        reports.forEach(function (report) {
            caregiver = DatabaseApi.getCaregiverById(report.caregiverId);
            if (!caregiver) {
                return;
            }
            if (report.patientId) {
                report.patient = DatabaseApi.getPatientById(report.patientId);
            }
            if (caregiver.appInstalled) {
                caregiver.confirmed = "installed";
            } else if (caregiver.appInstalledDate) {
                caregiver.confirmed = "uninstalled";
            } else {
                caregiver.confirmed = "notinstalled";
            }
            report.caregiver = caregiver;

            report.displayCoronaReport = false;
            $scope.coronaReports.push(report);
        });
    }

    function setScheduledMaps(caregiversWithScheduledArray, patientsWithScheduledArray) {
        caregiversWithScheduled = new Set(caregiversWithScheduledArray);
        patientsWithScheduled = new Set(patientsWithScheduledArray);
    }

    $scope.loadItems = function () {
        $scope.isLoading = true;
        const from = moment($scope.filterBy.fromDate);
        const to = moment($scope.filterBy.toDate);
        const scheduledShifts = $scope.filterBy.scheduledShifts;
        const url = wildcard(
            `agencies/:agencyId/agency_members/:agencyMemberId/corona_reports?from=${from.format("YYYY-MM-DD")}&to=${to.format("YYYY-MM-DD")}&scheduledShifts=${scheduledShifts}`,
            $rootScope.agencyId,
            $rootScope.agencyMemberId
        );
        DatabaseApi.get(url).then((res) => {
            setScheduledMaps(
                res.data.caregiversWithScheduled,
                res.data.patientsWithScheduled
            );
            let reports = [];
            for (var currentDate = moment(from); !currentDate.isAfter(to); currentDate.add(1, 'days')) {
                const currentDateString = currentDate.format('YYYY-MM-DD');
                Object.keys($scope.caregiversMap).forEach(caregiverIdString => {
                    const caregiverId = parseInt(caregiverIdString, 10);
                    const coronaReportOfCaregiver = res.data.caregiverReports.find(report => report.date === currentDateString && report.caregiverId === caregiverId);
                    if (coronaReportOfCaregiver !== undefined) {
                        reports.push(coronaReportOfCaregiver);
                    } else {
                        reports.push({
                            answers: [],
                            caregiverId: caregiverId,
                            createdAt: null,
                            date: currentDateString,
                            isManual: null,
                            reportId: null,
                            reportNotes: null,
                            risk: null,
                            submittedAt: null
                        });
                    }
                })
            }
            reports = reports.concat(res.data.patientReports);
            mapCoronaReports(reports);
            filterTable();
            $scope.isLoading = false;
        }, (err) => {
            toaster.pop("error", "Failed to load the corona reports");
            $scope.isLoading = false;
        });
    };

    $scope.preOpenCaregiverModal = function (row) {
        if (!row.caregiverId) return;
        if (!$scope.caregiversMap) $scope.caregiversMap = DatabaseApi.caregivers() || {};
        var caregiver = $scope.caregiversMap[row.caregiverId];
        $rootScope.openCaregiverModal(row.caregiverId, caregiver);
    };

    $scope.preOpenPatientModal = function (row) {
        if (!row.patientId) return;
        $rootScope.openPatientModal(row.patientId);
    }

    $scope.toggleCoronaReportDisplay = function (row) {
        if (!Array.isArray(row.answers)) {
            const url = wildcard(
                `agencies/:agencyId/agency_members/:agencyMemberId/corona_reports/:coronaReportId/answers`,
                $rootScope.agencyId,
                $rootScope.agencyMemberId,
                row.reportId
            );
            DatabaseApi.get(url).then(res => row.answers = res.data.answers);
        }
        row.displayCoronaReport = !row.displayCoronaReport;
    }

    $scope.toggleCoordinatorReportNotes = function (row) {
        row.displayCoordinatorNotes = !row.displayCoordinatorNotes;
    }

    $scope.exportTable = function () {
        // Filter table data by global filter
        const tableData = $filter('filter')($scope.tableData, $scope.globalFilter.val);
        if (tableData.length <= 0) {
            toaster.pop("warning", "Nothing to export", "The table is empty");
            return;
        }
        const withPatientColumns = $scope.filterBy.displayType === "Patients";

        const rows = [];
        const titles = [];
        for (const key in $scope.tableColumns) {
            if ($scope.tableColumns[key] && (withPatientColumns || key.indexOf("Patient") === -1)) {
                titles.push(key);
            }
        }

        rows.push(titles);
        tableData.sort(function (a, b) {
            return new Date(b.submittedAt) - new Date(a.submittedAt);
        });

        tableData.forEach(function (report) {
            if (!report.caregiver) {
                return;
            }
            const skipPatientCol = (!withPatientColumns || !report.patient);

            const row = [];
            titles.forEach(function (title) {
                let toPush = "";
                if (title === "Caregiver Name") toPush = report.caregiver.displayName || "";
                else if (title === "Caregiver Code") toPush = report.caregiver.caregiverCode || "";
                else if (title === "Caregiver Branch") toPush = report.caregiver.caregiverBranch || "";
                else if (title === "Caregiver Gender") toPush = report.caregiver.gender || "";
                else if (title === "Caregiver Birthdate") toPush = $filter("mfShortDate")(report.caregiver.birthDate) || "";
                else if (title === "Caregiver Languages") toPush = report.caregiver.languages.join(" | ") || "";
                else if (title === "Caregiver Certifications") toPush = report.caregiver.certifications.join(" | ") || "";
                else if (title === "Caregiver Installation") {
                    if (report.caregiver.confirmed === "installed") toPush = "Installed";
                    else if (report.caregiver.confirmed === "uninstalled") toPush = "Un-Installed";
                    else toPush = "Not Installed";
                }
                else if (title === "Caregiver Installed At") {
                    toPush = " ";
                    report.caregiver.appInstalledAt.forEach(function(d) {
                        if (toPush !== " ") toPush += " | ";
                        toPush += $filter("mfShortDate")(d);
                    });
                }
                else if (title === "Caregiver Uninstalled At") {
                    toPush = " ";
                    report.caregiver.appUninstalledAt.forEach(function(d) {
                        if (toPush !== " ") toPush += " | ";
                        toPush += $filter("mfShortDate")(d);
                    });
                }
                else if (title === "Caregiver Last Seen") {
                    if (report.caregiver.lastSeen === -1) toPush = "";
                    else toPush = $filter("mfShortDate")(report.caregiver.lastSeen) || "";
                }
                else if (title === "Caregiver Address") toPush = '"' + report.caregiver.address + '"' || "";
                else if (title === "Caregiver Phone") {
                    toPush = " ";
                    report.caregiver.phoneNumbers.forEach(function(p) {
                        if (toPush !== " ") toPush += " | ";
                        toPush += $filter("americanphone")(p.phoneNumber) + " (" + p.type + ")";
                    });
                }
                else if (title === "Caregiver Status") toPush = report.caregiver.status || "";
                else if (title === "Caregiver Hire Date") {
                    if (report.caregiver.hireDate === -1) toPush = "";
                    else toPush = $filter("mfShortDate")(report.caregiver.hireDate) || "";
                }
                else if (title === "Caregiver Area Preference") toPush = report.caregiver.workAreaPreference || "";
                else if (title === "Caregiver State Registry Number") toPush = report.caregiver.stateRegistryNum || "";
                else if (title === "Patient Name" && !skipPatientCol) toPush = report.patientName || "";
                else if (title === "Patient Created At" && !skipPatientCol) toPush = $filter('mfShortDate')(report.patient.createdAt) || '';
                else if (title === "Patient Address" && !skipPatientCol) {
                    if (!report.patient || !report.patient.address) toPush = '" "';
                    else toPush = '"' + report.patient.address.text.replace('#','') + '"' || '" "';
                }
                else if (title === "Patient Home Phone Number" && !skipPatientCol) toPush = $filter("americanphone")(report.patient.homePhoneNumber) || '';
                else if (title === "Patient Mobile Phone Number" && !skipPatientCol) toPush = $filter("americanphone")(report.patient.mobilePhoneNumber) || '';
                else if (title === "Patient Status" && !skipPatientCol) toPush = report.patient.status || '';
                else if (title === "Submitted At") toPush = $filter("mfShortTime")(report.submittedAt, ['withDate']) || "";
                else if (title === "Status") {
                    if (report.risk === true) toPush = "Risk";
                    else if (report.risk === false) toPush = "Done";
                    else toPush = "Missing";
                }
                else if (title === "Notes") toPush = report.reportNotes || "";

                row.push(toPush.toString().replace('\n', ' '));
            });
            rows.push(row);
        });

        let csvContent = "";
        rows.forEach(function (rowArray) {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const blob = new Blob([csvContent], { type: "text/csv" }),
            url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.setAttribute("href", url);
        const from = $scope.getLocalDateByDate($scope.filterBy.fromDate);
        const to = $scope.getLocalDateByDate($scope.filterBy.toDate);
        link.setAttribute("download", "medflyt-covid-19-reports-from-" + from + '-to-' + to + ".csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    };

    $scope.showCaregiverOffices = function(officeIds) {

        var offices = officeIds.map(function ( officeId) {
          return $scope.offices.find(office => office.id === officeId).label
        });
        return offices.join(", ");
    }

    $scope.openEditableReportStatus = function (report) {
        var newScope = $scope.$new();
        newScope.report = report;

        $uibModal.open({
            templateUrl: 'admin/views/corona-report-status-modal.html',
            size: 'lg',
            scope: newScope,
            controller: 'coronaReportStatusModalCtrl'
        });
    }

    $scope.updateFromOptions = () => {
        $scope.filterBy.toDate = angular.copy($scope.filterBy.fromDate);
        $scope.toDatePickerOptions = {
            minDate: moment($scope.filterBy.fromDate),
            maxDate: moment($scope.filterBy.fromDate).add(6, "days"),
            startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
        };
    };

    $rootScope.$on("got_data", function(event) {
        if ($scope.gotData) return;
        $scope.caregiversMap = DatabaseApi.caregivers() || {};
        initialize();
    });

    initialize();
 };
