import HeatmapOverlay from "leaflet-heatmap";

//! @ngInject
export function reportsCtrl($scope, $rootScope, $state, $uibModal, NgTableParams, DatabaseApi, AgencySettingsService, toaster) {

  window.scrollTo(0, 0);

  $scope.selected = { agency: '' };
  $scope.agenciesWithSmsSubscriptions = [];
  $scope.tasks = AgencySettingsService.getSettings();
  $scope.subscriptions = AgencySettingsService.getSubscriptions();

  DatabaseApi.getFromLocalServer('back-office-report/main').then(function (res) {
    //console.log(res.data);
    $scope.reportsData = res.data;
    initTable('agencyReport', res.data.agencyReport);
  }, function (err) {
    console.log(err.data);
  });

  function initTable(name, data) {

    $scope[name] = new NgTableParams({
      count: 25,
      sorting: { order: "asc" }
    }, {
        counts: [],
        dataset: data
      });
    //console.log($scope[name]);
  }


  if ($rootScope.user.superuser) {
    DatabaseApi.get('admin/agencies').then(function (res) {
      $scope.agencies = res.data.agencies;
    });
  }

  //if($rootScope.user.admin){
  function getReport(id) {
    id = parseInt(id);
    console.log(id);
    DatabaseApi.get('agencies/' + id + '/agency_member/' +  $rootScope.agencyMemberId + '/caregivers_dict2').then(function (res) {
      var installed = 0;
      var uninstalled = 0;
      var active = 0;

      $scope.caregiversData = {
        count: Object.keys(res.data).length,
        active: 0,
        pending: 0,
        on_hold: 0,
        on_leave: 0,
        suspended: 0,
        terminated: 0,
        installed: 0,
        uninstalled: 0,
        activeInstalled: 0,
        pendingInstalled: 0,
        on_holdInstalled: 0,
        on_leaveInstalled: 0,
        suspendedInstalled: 0,
        terminatedInstalled: 0,
        activeUninstalled: 0,
        pendingUninstalled: 0,
        on_holdUninstalled: 0,
        on_leaveUninstalled: 0,
        suspendedUninstalled: 0,
        terminatedUninstalled: 0,
      };

      $scope.totalCaregivers = $scope.caregiversData.count;

      //console.log(res.data.caregivers);
      for (var key in res.data) {
        var caregiver = res.data[key];
        if (caregiver.appInstalled) {
          installed++;
          if (caregiver.status === 'ACTIVE') active++;
        }
        else if (caregiver.appInstalledDate) uninstalled++;

        $scope.caregiversData[caregiver.status.toLowerCase()]++;
        if (!caregiver.appInstalled && caregiver.appInstalledDate) {
          $scope.caregiversData[caregiver.status.toLowerCase() + 'Uninstalled']++;
          $scope.caregiversData.uninstalled++;
        } else if (caregiver.appInstalled) {
          $scope.caregiversData[caregiver.status.toLowerCase() + 'Installed']++;
          $scope.caregiversData.installed++;
        }

      }

      $scope.installed = installed;
      $scope.uninstalled = uninstalled;
      $scope.totalActiveCaregivers = active;


    });

    DatabaseApi.get('admin/agencies/' + id + '/visit_stats').then(function (json) {
      var records = [];

      //console.log(json.data.visitStats);
      for (var index = 0; index < json.data.visitStats.length; index++) {
        var visit = json.data.visitStats[index];
        var visitId = visit.visitId;
        var createdAt = new Date(visit.visitDetails.createdAt);
        var assignedToTimeDelta = null;
        if (visit.assignedToEvents.length > 0) {
          var assignedToEvent = visit.assignedToEvents[0];
          var assignedToAt = new Date(assignedToEvent["@timestamp"]);
          assignedToTimeDelta = assignedToAt.getTime() - createdAt.getTime();
        }
        var caregiversRequested = new Set();
        var caregiversChatted = new Set();
        var caregiversPreViewed = new Set();
        var caregiversViewed = new Set();
        var caregiversNotInterested = new Set();
        for (var i = 0; i < visit.caregiverEvents.length; i++) {
          var ces = visit.caregiverEvents[i];
          for (var j = 0; j < ces.events.length; j++) {
            var ce = ces.events[j];
            if (ce.type === "VisitViewed") {
              caregiversViewed.add(ces.caregiverId);
            } else if (ce.type === "VisitPreviewed") {
              caregiversPreViewed.add(ces.caregiverId);
            } else if (ce.type === "ChatMessageSent") {
              caregiversChatted.add(ces.caregiverId);
            } else if (ce.type === "VisitRequested") {
              caregiversRequested.add(ces.caregiverId);
            } else if (ce.type === "VisitNotInterested") {
              caregiversNotInterested.add(ces.caregiverId);
            }
          }
        }

        var numPreViewed = caregiversPreViewed.size;
        var numViewed = caregiversViewed.size;
        var numNotInterested = caregiversNotInterested.size;
        var numRequested = caregiversRequested.size;
        var numChatted = caregiversChatted.size;

        records.push({
          isAssigned: visit.assignedToEvents.length ? true : false,
          visitId: visitId,
          visitType: visit.visitDetails.isSingleVisit ? 'single' : 'recurring',
          visitCreatedAt: createdAt,
          numMatchingCaregivers: visit.numMatchingCaregivers,
          numViewed: numViewed,
          numPreViewed: numPreViewed < numViewed ? numViewed : numPreViewed,
          numRequested: numRequested,
          numNotInterested: numNotInterested,
          numChatted: numChatted,
          assignedToTimeDelta: (assignedToTimeDelta === null ? null : assignedToTimeDelta / 1000 / 60)
        });
      }

      $scope.totalVisits = records.length;
      var allMatchingCaregivers = 0;
      var allPreViewedCase = 0;
      var allViewedCase = 0;
      var allRequested = 0;
      var allTimeDeltas = 0;
      var allChated = 0;
      var assigned = 0;
      records.forEach(function (record) {
        allMatchingCaregivers += record.numMatchingCaregivers;
        allPreViewedCase += record.numPreViewed;
        allViewedCase += record.numViewed;
        allRequested += record.numRequested;
        allChated += record.numChatted;
        allTimeDeltas += record.assignedToTimeDelta;
        if (record.assignedToTimeDelta) assigned++;
      });

      $scope.allRequested = allRequested;
      $scope.assigned = assigned;
      $scope.assignedPercent = Math.round(assigned / records.length);
      $scope.visitCount = records.length;
      $scope.averageMatchingCaregivers = Math.round(allMatchingCaregivers / records.length);
      $scope.averagePreViewsPerCase = Math.round(allPreViewedCase / records.length);
      $scope.averageViewsPerCase = Math.round(allViewedCase / records.length);
      $scope.averageRequested = Math.round(allRequested / records.length);
      $scope.averageChated = Math.round(allChated / records.length);

      var deltaSum = records.filter(function (record) {
        if (record.assignedToTimeDelta !== null) {
          return record;
        }
      });
      $scope.averageTimeDeltas = Math.round(allTimeDeltas / deltaSum.length);

      //console.log(records);
      $scope.visitTable = new NgTableParams({
        count: 25,
        sorting: { 'visitCreatedAt': 'desc' }
      }, {
          counts: [],
          dataset: records
        });
      $scope.showStats = true;

    }, function (err) {
      $scope.showStats = false;
    });
  }

  if ($rootScope.agencyId) getReport($rootScope.agencyId);

  $scope.goToVisit = function (id, type) {
    $rootScope.visitType = type;
    $rootScope.openVisitBroadcastModalById(id);
  };

  $scope.getReport = function (id) {
    $scope.activeAgency = id;
    getReport(id);
  };

  $scope.selectAgency = function (id) {
    console.log(id);
    var url = 'admin/reports/weekly-report';
    if (id) {
      $scope.allReport = false;
      url += '-' + id;
    } else {
      $scope.allReport = true;
    }
    DatabaseApi.get(url).then(function (res) {
      //console.log(res.data.weeks);
      $scope.weeklyReport = res.data.weeks;
    });
  };
  $scope.allReport = true;
  $scope.selectAgency();

  $scope.openAgencySettingsModal = function (agencyData) {

    console.log("agencyData", agencyData);

    const handleChangeTask = (taskId) => {
      console.log("handleChangeTask", taskId);
    }

    const modal = $uibModal.open({
        templateUrl: 'admin/views/agency-settings-modal.html',
        size: 'lg',
        controller: 'agencySettingsModal',
        windowTopClass: 'agency-settings-modal',
        resolve: {
          onChangeTask: () => (taskId) => handleChangeTask(taskId),
          agencyData: () => agencyData
        }
    });

    modal.result.then(function (res) {
      console.log('modal out');
      //console.log(res);
  }, function () {
      console.log('modal close');
  });
  }
  //
  //

  DatabaseApi.get('admin/agencies/getSmsSubscriptions').then(function (res) {
    // Initialize an empty dictionary
    var dataDict = {};

    // Initialize the final array
    var filteredAgencies = [];

    var rawData = res.data.agencyWithSmsSubscriptionIndicator;

    // Run on each row in the raw data from the API
    for (var key in rawData) {
      // Get data by key
      var data = rawData[key];

      // Check if key exists in dictionary
      if (!dataDict[data.id]) {
        // Create the base data with the given key in the dictionary
        dataDict[data.id] = {
          allowBoost: data.allowBoost,
          name: data.name,
          stripeCustomerId: data.stripeCustomerId,
          withPrizes: data.withPrizes,
          withoutPrizes: data.withoutPrizes,
          training: data.training,
          downloadInitial: data.downloadInitial,
          downloadOngoing: data.downloadOngoing,
          trainingInitial: data.trainingInitial,
          trainingOngoing: data.trainingOngoing,
          evvInitial: data.evvInitial,
          corona: data.corona,
          countSum: 0
        };
      }

      // Check if the current data is about the installed users count or the uninstalled users count
      if (data.installed) {
        dataDict[data.id].installed = +data.count;
      }
      else {
        dataDict[data.id].uninstalled = +data.count;
      }

      // Add to the total users in agency count
      dataDict[data.id].countSum += data.count;
    }

    // Run on each key in the dictionary
    Object.keys(dataDict).forEach(function (agencyId, index) {
      // Push the data from he dictionary to the final array
      filteredAgencies.push({
        id: +agencyId,
        allowBoost: dataDict[agencyId].allowBoost,
        name: dataDict[agencyId].name,
        installed: dataDict[agencyId].installed,
        uninstalled: dataDict[agencyId].uninstalled,
        percentInstalled: ((dataDict[agencyId].installed / dataDict[agencyId].countSum) * 100).toFixed(2),
        stripeCustomerId: dataDict[agencyId].stripeCustomerId,
        withPrizes: dataDict[agencyId].withPrizes,
        withoutPrizes: dataDict[agencyId].withoutPrizes,
        training: dataDict[agencyId].training,
        corona: dataDict[agencyId].corona,
        downloadInitial: dataDict[agencyId].downloadInitial,
        downloadOngoing: dataDict[agencyId].downloadOngoing,
        trainingInitial: dataDict[agencyId].trainingInitial,
        trainingOngoing: dataDict[agencyId].trainingOngoing,
        evvInitial: dataDict[agencyId].evvInitial
      });
    });

    $scope.agenciesWithSmsSubscriptions = filteredAgencies;
  })
  .catch(() => console.log("Failed to get sms subscriptions (possibly unauthorized)"));

  $scope.changeSetting = function (agencySettingName, agencyId, agencySettingValue) {
    var postData = {
      id: agencyId,
      [agencySettingName]: agencySettingValue
    };

    DatabaseApi.post('admin/agencies/' + agencySettingName, postData).then(function (res) {
      toaster.pop("success", "success with changing setting: " + agencySettingName);
    }, function (err) {
      toaster.pop("error", "error with changing settings: " + agencySettingName);
    });
  }

  /**
   * @deprecated
   */
  $scope.updateStripeCustomerId = function (agencyId, stripeCustomerId) {

    var postData = {
      id: agencyId,
      stripeCustomerId: stripeCustomerId
    };

    DatabaseApi.post('admin/agencies/stripeCustomerId', postData).then(function (res) {
      toaster.pop("success", "success with changing Stripe customer Id");
    }, function (err) {
      toaster.pop("error", "error with changing Stripe customer Id");
    });
  }

  // Map Section
  L.TileLayer.Grayscale = L.TileLayer.extend({
    options: {
      quotaRed: 21,
      quotaGreen: 71,
      quotaBlue: 8,
      quotaDividerTune: 64,
      quotaDivider: function () {
        return this.quotaRed + this.quotaGreen + this.quotaBlue + this.quotaDividerTune;
      }
    },

    initialize: function (url, options) {
      options = options || {}
      options.crossOrigin = true;
      L.TileLayer.prototype.initialize.call(this, url, options);

      this.on('tileload', function (e) {
        this._makeGrayscale(e.tile);
      });
    },

    _createTile: function () {
      var tile = L.TileLayer.prototype._createTile.call(this);
      tile.crossOrigin = "Anonymous";
      return tile;
    },

    _makeGrayscale: function (img) {
      if (img.getAttribute('data-grayscaled'))
        return;

      img.crossOrigin = '';
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var imgd = ctx.getImageData(0, 0, canvas.width, canvas.height);
      var pix = imgd.data;
      for (var i = 0, n = pix.length; i < n; i += 4) {
        pix[i] = pix[i + 1] = pix[i + 2] = (this.options.quotaRed * pix[i] + this.options.quotaGreen * pix[i + 1] + this.options.quotaBlue * pix[i + 2]) / this.options.quotaDivider();
      }
      ctx.putImageData(imgd, 0, 0);
      img.setAttribute('data-grayscaled', true);
      img.src = canvas.toDataURL();
    }
  });

  L.tileLayer.grayscale = function (url, options) {
    return new L.TileLayer.Grayscale(url, options);
  };

  var baseLayer = L.tileLayer.grayscale(
    'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '...',
      maxZoom: 18
    }
  );

  var cfg = {
    // radius should be small ONLY if scaleRadius is true (or small radius is intended)
    // if scaleRadius is false it will be the constant radius used in pixels
    //     "radius": 0.002,
    "radius": 0.02,
    "opacity": .5,
    // scales the radius based on map zoom
    "scaleRadius": true,
    // if set to false the heatmap uses the global maximum for colorization
    // if activated: uses the data maximum within the current map boundaries 
    //   (there will always be a red spot with useLocalExtremas true)
    "useLocalExtrema": false,
    // which field name in your data represents the latitude - default "lat"
    latField: 'lat',
    // which field name in your data represents the longitude - default "lng"
    lngField: 'lng',
    // which field name in your data represents the data value - default "value"
    valueField: 'count'
  };


  var heatmapLayer = new HeatmapOverlay(cfg);

  var map = new L.Map('map-canvas', {
    center: new L.LatLng(40.785091, -73.968285),
    zoom: 8,
    layers: [baseLayer, heatmapLayer]
  });

  DatabaseApi.get('admin/caregivers/getAllGeoLocations').then(function (res) {
    var testData = {
      max: 6,
      data: res.data.geoLocations
    };

    heatmapLayer.setData(testData);
  })
  .catch(() => console.log("Failed to get all geo locations (Possibly unauthorized)"));

  // Leaflet Map Section End
};
