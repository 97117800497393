export function agencyPermissionRoleModalCtrl($scope, agencyPermissionsRolesService, toaster, SweetAlert) {
    if ($scope.role === undefined) {
      $scope.role = {
        text: "",
        active: true,
      };
    }

    $scope.submit = function () {
      if ($scope.role.text === "") {
        toaster.pop("warning", "Role name is required");
        return;
      }

      agencyPermissionsRolesService.createNewRole($scope.role.text).then(
        ({ data }) => {
          toaster.pop(
            "success",
            "Role was " +
              ($scope.role.id ? "edited" : "created") +
              " successfully"
          );
          $scope.$close();
        },
        function (error) {
          toaster.pop("error", "Failed to create or edit role");
        }
      );
    };

    $scope.removeExistingRole = (role) => {
      SweetAlert.swal(
        {
          title: "Delete role",
          text: "Are you sure you want to delete this role?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3077EB",
          confirmButtonText: "Yes, delete",
          closeOnConfirm: true,
          closeOnCancel: true,
        },
        function (isConfirm) {
          if (isConfirm) {
            agencyPermissionsRolesService
              .deleteRole(role)
              .then(function (res) {
                toaster.pop("success", `Successfully removed ${role}`);
                $scope.$close();
              })
              .catch(function (err) {
                toaster.pop(
                  "error",
                  "Something went wrong",
                  `Failed to remove ${role} role`
                );
              });
          }
        }
      );
    };
  }
